













































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class ValidationSummary extends Vue {
  @Prop() private errorFields?: string[];
  @Prop() private ValidationSummaryHeading?: string;
  private localValidationSummaryHeading = '';
  private mounted() {
    this.localValidationSummaryHeading = this.ValidationSummaryHeading === null || this.ValidationSummaryHeading === undefined ? this.$t('Warning.Message').toString() : this.ValidationSummaryHeading;
  }
  private Close() {
    this.$emit('close-click', false);
  }
}
